.web-logo img {
  width: 10vw;
}

.carousel-caption {
  top: 10%;
  transform: translateY(-10%);
}

.carousel-caption p.carousel-header {
  color: #000;
  font-size: 4vw;
  font-weight: bolder;
}

.carousel-caption p.carousel-content {
  color: #000;
  font-size: 2vw;
  text-align: left;
}

.carousel-caption button {
  width: 12vw;
  font-size: 1.1vw;
}

.carousel-item {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.col {
  padding: 0 !important;
}

.card {
  border: none !important;
}

.card img {
  border-radius: 0;
}

.newsletter {
  background-color: #f3f3f3;
}

.form-newsletter {
  width: 50vw;
  display: flex;
  justify-content: center;
}

.form-newsletter input {
  border: 1px solid #acacac;
  border-radius: 0;
}

.subs-btn {
  border-radius: 0 !important;
}

.footdesign {
  background-color: #171717;
  color: #fff;
}

.afooter {
  display: block;
  color: #fff;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: bolder;
}

.ulfooter {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.gmap {
  max-width: 100%;
  list-style: none;
  transition: none;
  overflow: hidden;
  width: 750px;
  height: 500px;
}
/* Image Hover */
.collection-container {
  position: relative;
}

.container-image {
  opacity: 1;
  display: block;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.collection-container:hover .container-image {
  opacity: 0.5;
}

.collection-container:hover .middle {
  opacity: 1;
}

.collection text {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.footlink {
  color: #fff;
  text-decoration: none;
}
/* End of Image Hover */

@media only screen and (max-width: 1000px) {
  .web-logo img {
    width: 25vw;
  }

  .carousel-caption p.carousel-header {
    margin-top: 15px;
    font-size: 3vw;
    font-weight: bolder;
  }

  .carousel-caption p.carousel-content {
    color: #000;
    font-size: 2vw;
    margin-top: -10px;
  }

  .carousel-caption button.btn {
    width: 14vw;
    font-size: 1.2vw;
    margin-top: -10px;
    padding: 0px auto;
  }

  .newsletter {
    text-align: center;
  }

  .form-newsletter {
    width: 100vw;
  }

  .cart-button{
    margin-top: 20px;
  }

  .order-form{
    width: 80% !important;
  }
}

.cart-photo{
  width: 50px;
  height: 50px;

}

.qty-button {
  margin: 5px;
  color: #000;
  border: none;
  cursor: pointer;
  font-size: 12px;
  width: 20px;
  text-align: center;
  transition: background-color 0.2s;
}

.cart-box{
  width:100%;
  padding: 150px 0;
}

.cart-box-small{
  width:100%;
  padding: 20px 0;
  text-align: center;
}

.cart-big{
  margin-bottom: 100px;
}
.cart-table{
  border: #fff !important;
}

.order-form{
  margin: 0px auto;
  width: 50%;
}